.status {
    border-radius: 7px;
    font-weight: 600;
    white-space: nowrap;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    user-select: none;
}

.green {
    border: 2px solid #32cd32;
    background-color: #e9ffdb ;
    color: #32cd32;
    
}

.orange {
    border: 2px solid #ff8c00;
    background-color: #ffd7a4 ;
    color: #ff8c00;
}

.gray {
    border: 2px solid #808080;
    background-color: #dcdcdc ;
    color: #808080;
}

.trash {
    cursor: pointer;
}

.w-search {
    width: 300px;
}

.add-new {
    white-space: nowrap;
}

.table-css {
    overflow-x: auto;
}

.ant-table-thead th {
    background-color: #405189 !important;
    color: white !important;
  }

.ant-table-thead > tr > th::before {
    background-color: white !important
}

.ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}

.ant-table-container table > thead > tr:last-child th:last-child {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}